export const splitStr2 = (string, str, onlyLast) => {
  if (!string || !str) return [];
  if (str.length > string.length) return [];

  const index = onlyLast ? string.lastIndexOf(str) : string.indexOf(str);
  if (index === -1) return [];

  const str1 = string.substr(0, index).trim();
  const str2 = string.substr(index + str.length).trim();
  return [str1, str2];
};

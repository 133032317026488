export const translation = {
  common: {
    userName_invalid: "Length must be at least 6",
    userName_taken: "User name is taken",
    fullName_empty: "Please enter full name",
    email_invalid: "Email is invalid",
    email_taken: "Email is taken",
    phoneNumber_empty: "Please enter phone number",
    password_invalid: "Password is invalid",
    confirmPassword_incorrect: "Confirm password is incorrect",
  },
  login: {
    info_incorrect: "Login info is incorrect",
    system_maintenance: "The system is maintenance",
    user_banned: "User has been banned",
  },
};

import { Subject } from "rxjs";

import * as Service from "../../utility/serviceHelper";
import * as Const from "../common/const";

const subject = new Subject();
let _current = null;

export const CurrentService = {
  check: async () => {
    const res = await Service.get("api/user/current/check");
    if (res.success && res.data) _current = res.data;
    else {
      _current = null;
      if (res.unauthorized) window.location = Const.loginURL;
    }

    subject.next(_current);

    return _current;
  },
  getCurrent: () => _current,
  setCurrent: (current) => {
    _current = current;
    subject.next(_current);
  },
  getObservable: () => subject.asObservable(),
};

import { makeStyles } from "@mui/styles";

export const rootStyles = makeStyles({
  root: {
    minHeight: "100vh",
    width: "100%",
    display: "flex",
    backgroundColor: "#364150",

    "& *:not(i)": {
      fontFamily: "'Open Sans' ,sans-serif",
      fontSize: 14,
    },

    "& .button": {
      padding: "10px 20px",
      color: "#fff",
      backgroundColor: "#e12330",
      border: "1px solid #e12330",
      fontWeight: "600",
      lineHeight: 1.44,
      textTransform: "uppercase",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#e12330",
        borderColor: "#dc1e2b",
      },
      "&[disabled]": {
        cursor: "not-allowed",
        opacity: 0.65,
      },
      "&.primary": {
        backgroundColor: "#337ab7",
        borderColor: "#2e6da4",
        padding: "6px 12px",
        textTransform: "none",
      },
    },
  },
});

export const dashboardStyles = makeStyles({
  container: {
    flex: 1,

    "@media (min-width: 992px)": {
      "& .btnMenuLeft": {
        display: "block !important",
      },
      "& .btnMenuRight": {
        display: "none !important",
      },
    },
    "@media (max-width: 991px)": {
      "& .btnMenuLeft": {
        display: "none !important",
      },
      "& .btnMenuRight": {
        display: "block !important",
      },
      "& .right": {
        flexWrap: "wrap",

        "& .toolbar": {
          width: "100%",
          backgroundColor: "transparent !important",
        },
      },
    },
    "@media (max-width: 580px)": {
      "& .right": {
        "& .userBox": {
          justifyContent: "flex-end !important",

          "& .ipReport": {
            display: "none !important",
          },
        },
      },
    },
  },
  header: {
    display: "flex",
    flexDirection: "row",
    minHeight: 50,

    "& .left": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: 50,
      width: 192,
      paddingLeft: 10,
      paddingRight: 10,
      backgroundColor: "#fff",

      "& .logo": {
        width: 100,
        height: 45,
      },
    },

    "& .right": {
      flex: 1,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",

      "& .userBox": {
        height: 50,
        flexGrow: 1,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginLeft: 10,
        backgroundColor: "#2b3643",

        "& .ipReport": {
          fontSize: 12,
          fontWeight: "600",
          color: "#fff",

          "& span": {
            fontSize: 12,
            textTransform: "uppercase",
          },
        },
      },

      "& .toolbar": {
        height: 50,
        display: "flex",
        justifyContent: "flex-end",
        backgroundColor: "#2b3643",

        "& a": {},

        "& .menu": {
          display: "none",
        },
      },
    },

    "& .icon": {
      padding: "0 10px",
      color: "#a7b5c6",
      opacity: 0.6,
      fontSize: 18,
      lineHeight: 1,
      cursor: "pointer",

      "&.logout": {
        fontSize: 20,

        "& i": {
          transform: "rotate(180deg)",
        },
      },
    },
    "& a.icon": {
      display: "inline-flex",
      alignItems: "center",

      "&:hover": {
        backgroundColor: "#3f4f62",
      },
    },
  },
  body: {
    flexGrow: 1,
    display: "flex",
    minHeight: 864,

    "& .left": {
      width: 195,
    },

    "& .right": {
      flexGrow: 1,
      padding: 40,
      backgroundColor: "#fff",

      "& > .title": {
        marginBottom: 20,
        padding: "5px 0 15px",
        fontSize: 16,
        textTransform: "uppercase",
        color: "#E26A6A",
        fontWeight: "bolder",
        borderBottom: "1px solid #eee",
      },

      "& .reportBox": {
        border: "1px solid #e7ecf1",
        padding: 20,

        "& .title": {
          marginBottom: 20,
          paddingBottom: 15,
          fontSize: 16,
          textTransform: "uppercase",
          color: "#2f353b",
          fontWeight: "bolder",
          borderBottom: "1px solid #eee",
        },
      },
    },

    "@media (max-width: 991px)": {
      "& .left": {
        display: "none",
      },
    },

    "@media (max-width: 767px)": {
      "& .right": {
        padding: "30px 10px",

        "& .reportBox": {
          padding: "20px 10px !important",
        },
      },
    },
  },
  footer: {
    display: "flex",
    justifyContent: "center",
    padding: "10px 0",
    textAlign: "center",
    color: "#98a6ba",
  },
  imagePopup: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,

    "& .backdrop": {
      position: "absolute",
      zIndex: 0,
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "#33333388",
    },

    "& .container": {
      position: "relative",
      zIndex: 1,
      maxWidth: 800,
      width: "100%",
      height: 600,
      margin: "25px auto",
      backgroundColor: "#fff",

      "& > .title": {
        padding: "25px 15px 15px",
        fontSize: 16,
        color: "#E26A6A",
        fontWeight: "bolder",
        borderBottom: "1px solid #eee",
      },

      "& .imageItem": {
        display: "inline-flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "20px 30px",
        cursor: "pointer",

        "& img": {
          width: 100,
          height: 100,
        },
        "& button": {
          marginTop: 5,
        },
      },
    },

    "@media (max-width: 600px)": {
      "& .container": {
        margin: 0,
      },
    },
  },
});

export const loginStyles = makeStyles({
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "0 20px",
  },
  form: {
    marginTop: 200,
    marginBottom: 10,
    backgroundColor: "#fff",
    maxWidth: 400,
    minWidth: 350,
    width: "100%",
    padding: "10px 30px 30px",

    "@media (max-width: 440px)": {
      "&": {
        marginTop: 70,
      },
    },
    "& .title": {
      margin: "20px 0 25px",
      color: "#e7505a",
      fontSize: 28,
      lineHeight: 1.1,
      fontWeight: "400",
      textAlign: "center",
    },
    "& .error": {
      padding: 15,
      marginBottom: 20,
      backgroundColor: "#fbe1e3",
      borderColor: "#fbe1e3",
      color: "#e73d4a",
    },
    "& input": {
      display: "block",
      marginBottom: 15,
      height: 43,
      width: "100%",
      padding: "6px 10px",
      backgroundColor: "#dde3ec",
      border: "1px solid #dde3ec",
      color: "#8290a3",
      outline: 0,
    },
    "& .footer": {
      padding: "25px 0",
      textAlign: "center",
      borderBottom: "1px solid #eee",
    },
    "& a": {
      color: "#e73d4a",
      textDecoration: "none",
      outline: 0,

      "&:hover": {
        color: "#d71b29",
        textDecoration: "underline",
      },
    },
  },
  copyright: {
    textAlign: "center",
    marginBottom: 30,
    padding: 10,
    color: "#7a8ca5",
    fontSize: 13,
  },
});

import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { toPng } from "html-to-image";

const ToImage = ({ report, compareList, timeList, onComplete }) => {
   const classes = useStyles();
   const [reportCompare, reportCompareSet] = useState();
   const [reportTime, reportTimeSet] = useState();

   useEffect(() => {
      compareListParse();
      timeListParseList();
   }, []);

   useEffect(() => {
      if (!reportCompare || !reportTime) return;
      toImage();
   }, [reportCompare, reportTime]);

   const compareListParse = () => {
      let curItem = null;
      let rowIndex = 0;
      let reportList = [];

      for (let index = 0; index < compareList.length; index++) {
         const item = compareList[index];

         if (item.index) {
            curItem = [item, {}];
            curItem[0].childCount = 0;
            reportList[rowIndex] = curItem;
         } else {
            if (curItem) {
               curItem[0].childCount += 1;
               if (curItem[1].id) {
                  reportList[rowIndex] = [{}, item];
               } else {
                  curItem[1] = item;
               }
               rowIndex += 1;
            } else {
               reportList[rowIndex] = [{}, item];
            }
         }
      }
      reportCompareSet([...reportList]);
   };

   const timeListParseList = () => {
      let curItem = null;
      let reportList = [];
      for (let index = 0; index < timeList.length; index++) {
         const item = timeList[index];
         let isRowEnd = false;

         if (item.index) {
            curItem = item;
            curItem.childCount = 1;
            if (index > 0) timeList[index - 1].rowEnd = true;
         } else {
            if (curItem) {
               curItem.childCount += 1;
            }
         }
         if (index === timeList.length - 1) isRowEnd = true;
         item.rowEnd = isRowEnd;
         reportList.push(item);
      }
      reportTimeSet([...reportList]);
   };

   const memberStyle = (member) => <span className={member === report.member ? "highlight" : ""}>{member}</span>;

   const toImage = () => {
      var reportDataBox = document.getElementById("reportDataBox");
      var reportImageBox = document.getElementById("reportImageBox");

      toPng(reportDataBox)
         .then(function (dataUrl) {
            var img = new Image();
            img.src = dataUrl;
            img.style = "width:100%";
            reportImageBox.innerHTML = "";
            reportImageBox.appendChild(img);
            onComplete(dataUrl);
         })
         .catch(function (error) {
            console.error("oops, something went wrong!", error);
         });
   };

   return (
      <div className={classes.root}>
         <div id="reportDataBox" className={classes.dataBox}>
            <div className="container">
               <div className={classes.header}>BÁO CÁO PHÂN TÍCH</div>
               <div className="body">
                  <table className={classes.infoTable}>
                     <tbody>
                        <tr>
                           <td>
                              <div className="box">
                                 <span className="arrow" />
                                 <span className="label">Tài khoản:</span>
                                 <b className="highlight">{report.member}</b>
                              </div>
                           </td>
                           <td>
                              <div className="box">
                                 <span className="arrow red" />
                                 <span className="label red">Kết luận:</span>
                                 <b className="red">{report.message}</b>
                              </div>
                           </td>
                           <td>
                              <div className="box">
                                 <span className="arrow" />
                                 <span className="label">Ngày:</span>
                                 <span>{report.date}</span>
                              </div>
                           </td>
                        </tr>
                        <tr>
                           <td>
                              <div className="box">
                                 <span className="arrow" />
                                 <span className="label">ID BCPT:</span>
                                 <b className="red">{report.idReport}</b>
                              </div>
                           </td>
                           <td>
                              <div className="box">
                                 <span className="arrow red" />
                                 <span className="label">Phân Tích:</span>
                                 <span>{report.analysis}</span>
                              </div>
                           </td>
                           <td>
                              <div className="box">
                                 <span className="arrow" />
                                 <span className="label">Rủi ro:</span>
                                 <span className="redlight" style={{ width: 42, height: 13 }}></span>
                              </div>
                              <div className="box">
                                 <span className="arrow" />
                                 <span className="label">Cấp độ:</span>
                                 <span>{report.level}</span>
                              </div>
                           </td>
                        </tr>
                     </tbody>
                  </table>
                  <div className={classes.notice}>
                     Gần đây trên thị trường xuất hiện nhiều Báo cáo Pân tích giả mạo VScan, Quý khách lưu ý Vscan chỉ sử dụng duy nhất 01 mẫu Báo
                     cáo Phân tích có kèm User và Pass đăng nhập trên sp.vscan.cc
                  </div>
                  <div className={classes.tableTitle}>So sánh ticket:</div>
                  <table className={classes.compareTable}>
                     <thead>
                        <tr>
                           <th>#</th>
                           <th>Thời gian</th>
                           <th>Ref no</th>
                           <th>Tên tài khoản</th>
                           <th>Case</th>
                           <th>Bet</th>
                           <th>Bet value</th>
                           <th>Odds</th>
                           <th>IP</th>
                           <th></th>
                           <th>Thời gian</th>
                           <th>Ref no</th>
                           <th>Tên tài khoản</th>
                           <th>Case</th>
                           <th>Bet</th>
                           <th>Bet value</th>
                           <th>Odds</th>
                           <th>IP</th>
                           <th>Giây</th>
                           <th>Trùng IP</th>
                        </tr>
                     </thead>
                     <tbody>
                        {reportCompare &&
                           reportCompare.map((item, index) => (
                              <tr key={index}>
                                 {item[0].id && (
                                    <>
                                       <td rowSpan={item[0].childCount}>{item[0].index}</td>
                                       <td rowSpan={item[0].childCount}>{item[0].time}</td>
                                       <td rowSpan={item[0].childCount}>{item[0].refNo}</td>
                                       <td rowSpan={item[0].childCount}>{memberStyle(item[0].member)}</td>
                                       <td rowSpan={item[0].childCount}>{item[0].case}</td>
                                       <td rowSpan={item[0].childCount}>{item[0].bet}</td>
                                       <td rowSpan={item[0].childCount}>{item[0].betValue}</td>
                                       <td rowSpan={item[0].childCount}>{item[0].odds}</td>
                                       <td rowSpan={item[0].childCount}>{item[0].ip}</td>
                                       <td rowSpan={item[0].childCount}></td>
                                    </>
                                 )}
                                 <td>{item[1].time}</td>
                                 <td>{item[1].refNo}</td>
                                 <td>{item[1].member}</td>
                                 <td>{item[1].case}</td>
                                 <td>{item[1].bet}</td>
                                 <td>{item[1].betValue}</td>
                                 <td>{item[1].odds}</td>
                                 <td>{item[1].ip}</td>
                                 <td>{item[1].second}</td>
                                 <td>{item[1].message}</td>
                              </tr>
                           ))}
                     </tbody>
                  </table>
                  <div style={{ height: 100 }} />
                  <div className={classes.tableTitle}>Các thời điểm:</div>
                  <table className={classes.timesTable}>
                     <thead>
                        <tr>
                           <th>STT</th>
                           <th>Ref no</th>
                           <th>Company</th>
                           <th>IP</th>
                           <th>Member</th>
                           <th>Case</th>
                           <th>Bet</th>
                           <th>Type</th>
                           <th>Bet value</th>
                           <th>Odds</th>
                           <th>Stake</th>
                           <th>Thời gian</th>
                           <th>Ghi chú</th>
                        </tr>
                     </thead>
                     <tbody>
                        {reportTime &&
                           reportTime.map((item, index) => (
                              <tr className={item.rowEnd ? "row-end" : ""} key={index}>
                                 {item.index !== "" && <td rowSpan={item.childCount}>{item.index}</td>}
                                 <td>{item.refNo}</td>
                                 <td>{item.company}</td>
                                 <td>{item.ip}</td>
                                 <td>{memberStyle(item.member)}</td>
                                 <td>{item.case}</td>
                                 <td>{item.bet}</td>
                                 <td>{item.type}</td>
                                 <td>{item.betValue}</td>
                                 <td>{item.odds}</td>
                                 <td>{item.stake}</td>
                                 <td>{item.time}</td>
                                 {item.index !== "" && <td rowSpan={item.childCount}>{item.message}</td>}
                              </tr>
                           ))}
                     </tbody>
                  </table>
                  <div style={{ height: 70 }} />
                  <div className={classes.footer}>
                     <div className={classes.left}>
                        <div>Website: vscan.cc</div>
                        <br />
                        <div>Liên hệ Telegram: @vscancc</div>
                     </div>
                     <div className={classes.center}>HÌNH ẢNH BÁO CÁO PHÂN CÔNG TY CHỈ LƯU TRỮ VÀ HỖ TRỢ XỬ LÝ TRONG VÒNG 14 NGÀY</div>
                     <div className={classes.right}>Hình ảnh độc quyền bởi Vscan</div>
                  </div>
               </div>
            </div>
         </div>
         <div id="reportImageBox"></div>
      </div>
   );
};

const useStyles = makeStyles({
   root: {
      overflow: "auto",
      width: 1,
      height: 1,
   },
   dataBox: {
      width: "fit-content",
      padding: 5,
      backgroundColor: "#fff",
      backgroundImage: "url(/image/logo-bg.png)",
      backgroundSize: "35%",
      fontFamily: "arial",

      "& .container": {
         border: "1px #dfdfdf solid",

         "& .body": {
            padding: "12px 12px 2px 12px ",
         },
      },
      "& table": {
         width: "100%",
         borderCollapse: "collapse",
         border: "1px #dfdfdf solid",
         borderBottomWidth: 0,
         borderRightWidth: 0,

         "& th, & td": {
            padding: "5px 10px",
            whiteSpace: "nowrap",
            verticalAlign: "middle",
            borderBottom: "1px #dfdfdf solid",
            borderRight: "1px #dfdfdf solid",
         },
         "& th": {
            backgroundColor: "#e9e9e9",
         },
      },
      "& >*": {
         color: "#202020",
         fontSize: 12,
         fontFamily: "arial",
      },
      "& .red": {
         color: "#f00",
      },
      "& .highlight": {
         backgroundColor: "#ff0",
      },
      "& .redlight": {
         backgroundColor: "#f00",
      },
   },
   header: {
      height: 90,
      paddingTop: 42,
      backgroundImage: "url(/image/header-bg.png)",
      backgroundPosition: "left top",
      backgroundSize: "cover",
      textAlign: "center",
      fontSize: 26,
      fontWeight: "bold",
   },
   infoTable: {
      backgroundColor: "#f1f1f1",
      "& td": {},
      "& .box": {
         display: "flex",
         alignItems: "center",

         "& .icon": {
            width: 5,
            height: 12,
            marginRight: 5,
         },

         "& .arrow": {
            width: 0,
            height: 0,
            borderTop: "5px solid transparent",
            borderBottom: "5px solid transparent",
            borderLeft: "5px solid #202020",
            marginRight: 5,

            "&.red": {
               borderLeftColor: "#f00",
            },
         },

         "& .label": {
            fontWeight: "bold",
            marginRight: 25,
         },
      },
   },
   notice: {
      padding: "24px 0",
      textAlign: "center",
      fontWeight: "bold",
      color: "#4136ef",
   },
   tableTitle: {
      marginBottom: 5,
   },
   compareTable: {
      "& th, & td": {
         textAlign: "center",
      },
   },
   timesTable: {
      "& th, & td": {
         textAlign: "center",
      },
      "& .row-end": {
         // borderBottom: "3px #0000ff solid",
      },
      "& td[rowspan]": {
         verticalAlign: "top",
         // borderBottom: "3px #0000ff solid",
      },
   },
   footer: {
      display: "flex",
      alignItems: "center",
   },
   left: {
      whiteSpace: "nowrap",
   },
   center: {
      flexGrow: 1,
      textAlign: "center",
      color: "red",
      fontSize: 15,
      whiteSpace: "nowrap",
   },
   right: {
      whiteSpace: "nowrap",
   },
});

export default ToImage;

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { OutlinedInput, IconButton, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { AddOutlined, RemoveOutlined } from "@mui/icons-material";

import moment from "moment";

import * as Const from "../common/const";

import DrawerComp from "../common/drawerComp";
import { Msg, onChangeState } from "../../utility/form";
import { splitStr2 } from "../../utility/string";

import { ReportPostService } from "./vscanService";

import ToImage from "./toImage";

const ReportPostPage = () => {
  const classes = useStyles();
  let params = useParams();
  const [id, setId] = useState(params.id);
  const [compState, setCompState] = useState({});
  const [reportState, setReportState] = useState();
  const [compareState, setCompareState] = useState([]);
  const [timeState, setTimeState] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isCreateImage, setIsCreateImage] = useState(false);

  useEffect(() => {
    ReportPostService.init({ id }).then((res) => {
      if (!res.success || !res.item) return;

      setCompState({
        title: id ? "Edit report: #" + id : "Add report",
      });
      const { reportCompareList, reportTimeList, ...report } = res.item;
      setReportState(report);
      setCompareState(reportCompareList ?? []);
      setTimeState(reportTimeList ?? []);
      if (id)
        setTimeout(() => {
          setIsCreateImage(true);
        });
    });
  }, []);

  const onReportPropChange = (prop) => (event) => {
    reportState[prop] = event.target.value;
    // setCompareState([...compareState]);
  };

  const onComparePropChange = (prop, item) => (event) => {
    item[prop] = event.target.value;
    // setCompareState([...compareState]);
  };

  const onComparePaste = (item) => (event) => {
    const pasteData = (event.clipboardData || window.clipboardData).getData("text");
    if (compareParsePasteData(pasteData, item)) window.event.preventDefault();
  };
  const compareParsePasteData = (data, item) => {
    const betData = parseBetData(data);
    if (betData) {
      for (const key in betData) item[key] = betData[key];
      item.id = "-" + new Date().getTime();
      setCompareState([...compareState]);
      return true;
    } else return false;
  };

  const onTimePaste = (item) => (event) => {
    const pasteData = (event.clipboardData || window.clipboardData).getData("text");
    if (timeParsePasteData(pasteData, item)) window.event.preventDefault();
  };
  const timeParsePasteData = (data, item) => {
    const betData = parseBetData(data);
    if (betData) {
      for (const key in betData) item[key] = betData[key];
      item.id = "-" + new Date().getTime();
      setTimeState([...timeState]);
      return true;
    } else return false;
  };

  const parseBetData = (data) => {
    if (!data) return null;
    const dataList = data.split("\n");
    if (dataList.length < 16) return null;

    const refNo = dataList[0].substr(8);

    const time = moment(dataList[1]).format("DD/MM/YYYY HH:mm:ss");

    const betParse = splitStr2(dataList[2], " ", true);
    const bet = betParse[0] === "Over" || betParse[0] === "Under" ? betParse[0].toUpperCase() : betParse[0];
    const betValue = betParse[1];

    const caseStr = splitStr2(dataList[4], "- vs -").join(" - ");

    const odds = dataList[7];

    const stake = dataList[9];

    const ip = dataList[15];

    return { refNo, time, bet, betValue, case: caseStr, odds, stake, ip };
  };

  const onTimePropChange = (prop, item) => (event) => {
    item[prop] = event.target.value;
    // setTimeState([...timeState]);
  };

  const itemAdd = (index, prop, setProp) => (event) => {
    prop.splice(index, 0, {
      id: "-" + new Date().getTime(),
      index: "",
      member: reportState.member,
      company: "Bong88",
      type: "Live",
    });
    setProp([...prop]);
  };

  const itemDelete = (index, prop, setProp) => (event) => {
    prop.splice(index, 1);
    setProp([...prop]);
  };

  const onSubmit = (hasImage, imageData) => {
    setIsSubmit(true);
    if (hasImage) {
      ReportPostService.submit({
        ...reportState,
        imageData,
        reportCompareList: [...compareState],
        reportTimeList: [...timeState],
      }).then((res) => {
        if (res.success) {
          if (!id) window.location = "/reportPostPage/" + res.data;
        }
      });
      setIsSubmit(false);
    } else {
      setIsCreateImage(true);
    }
  };

  const onToImageComplete = (imageData) => {
    setReportState({ ...reportState, imageData: imageData });
    setIsCreateImage(false);
    if (isSubmit) onSubmit(true, imageData);
  };

  const loginURLGet = () => Const.loginURL + "?user=" + reportState?.idReport;

  if (!reportState) return <></>;

  return (
    <DrawerComp
      title={compState.title}
      button={
        <LoadingButton loading={isSubmit} variant="contained" onClick={() => onSubmit()}>
          Submit
        </LoadingButton>
      }
    >
      <div className={classes.root}>
        <table>
          <tbody>
            <tr>
              <th style={{ width: 100 }}>Tài khoản:</th>
              <td style={{ width: 250 }}>
                <OutlinedInput defaultValue={reportState.member} onChange={onReportPropChange("member")} />
              </td>
              <th style={{ width: 100 }}>Kết luận:</th>
              <td>
                <OutlinedInput defaultValue={reportState.message} onChange={onReportPropChange("message")} />
              </td>
              <th style={{ width: 100 }}>Ngày:</th>
              <td style={{ width: 160 }}>
                <OutlinedInput defaultValue={reportState.date} onChange={onReportPropChange("date")} />
              </td>
            </tr>
            <tr>
              <th>ID BCPT:</th>
              <td>
                <OutlinedInput defaultValue={reportState.idReport} onChange={onReportPropChange("idReport")} />
              </td>
              <th>Phân Tích:</th>
              <td>
                <OutlinedInput defaultValue={reportState.analysis} onChange={onReportPropChange("analysis")} />
              </td>
              <th>Cấp độ:</th>
              <td>
                <OutlinedInput defaultValue={reportState.level} onChange={onReportPropChange("level")} />
              </td>
            </tr>
            <tr>
              <th>Password:</th>
              <td>
                <OutlinedInput defaultValue={reportState.password} onChange={onReportPropChange("password")} />
              </td>
              <th>Login URL:</th>
              <td>
                <OutlinedInput defaultValue={loginURLGet()} readOnly />
              </td>
            </tr>
          </tbody>
        </table>
        <p>Compare ticket</p>
        <table>
          <thead>
            <tr>
              <th style={{ width: 1 }}>
                <IconButton color="info" onClick={itemAdd(0, compareState, setCompareState)}>
                  <AddOutlined />
                </IconButton>
              </th>
              <th style={{ width: 1 }}></th>
              <th width={40}>#</th>
              <th style={{ width: 150 }}>Thời gian</th>
              <th style={{ width: 130 }}>Ref no</th>
              <th style={{ width: 140 }}>Tên tài khoản</th>
              <th>Case</th>
              <th>Bet</th>
              <th style={{ width: 70 }}>Bet value</th>
              <th style={{ width: 70 }}>Odds</th>
              <th style={{ width: 120 }}>IP</th>
              <th style={{ width: 80 }}>Giây</th>
              <th style={{ width: 140 }}>Trùng IP</th>
            </tr>
          </thead>
          <tbody>
            {compareState.map((item, index) => {
              return (
                <tr key={item.id}>
                  <td>
                    <IconButton color="info" onClick={itemAdd(index + 1, compareState, setCompareState)}>
                      <AddOutlined />
                    </IconButton>
                  </td>
                  <td>
                    <IconButton color="error" onClick={itemDelete(index, compareState, setCompareState)}>
                      <RemoveOutlined />
                    </IconButton>
                  </td>
                  <td>
                    <OutlinedInput defaultValue={item.index} onChange={onComparePropChange("index", item)} />
                  </td>
                  <td>
                    <OutlinedInput defaultValue={item.time} onChange={onComparePropChange("time", item)} onPaste={onComparePaste(item)} />
                  </td>
                  <td>
                    <OutlinedInput defaultValue={item.refNo} onChange={onComparePropChange("refNo", item)} />
                  </td>
                  <td>
                    <OutlinedInput defaultValue={item.member} onChange={onComparePropChange("member", item)} />
                  </td>
                  <td>
                    <OutlinedInput defaultValue={item.case} onChange={onComparePropChange("case", item)} />
                  </td>
                  <td>
                    <OutlinedInput defaultValue={item.bet} onChange={onComparePropChange("bet", item)} />
                  </td>
                  <td>
                    <OutlinedInput defaultValue={item.betValue} onChange={onComparePropChange("betValue", item)} />
                  </td>
                  <td>
                    <OutlinedInput defaultValue={item.odds} onChange={onComparePropChange("odds", item)} />
                  </td>
                  <td>
                    <OutlinedInput defaultValue={item.ip} onChange={onComparePropChange("ip", item)} />
                  </td>
                  <td>
                    <OutlinedInput defaultValue={item.second} onChange={onComparePropChange("second", item)} />
                  </td>
                  <td>
                    <OutlinedInput defaultValue={item.message} onChange={onComparePropChange("message", item)} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <p>Times</p>
        <table>
          <thead>
            <tr>
              <th style={{ width: 1 }}>
                <IconButton color="info" onClick={itemAdd(0, timeState, setTimeState)}>
                  <AddOutlined />
                </IconButton>
              </th>
              <th style={{ width: 1 }}></th>
              <th width={40}>STT</th>
              <th style={{ width: 130 }}>Ref no</th>
              <th style={{ width: 80 }}>Company</th>
              <th style={{ width: 120 }}>IP</th>
              <th style={{ width: 140 }}>Member</th>
              <th>Case</th>
              <th>Bet</th>
              <th style={{ width: 70 }}>Type</th>
              <th style={{ width: 70 }}>Bet value</th>
              <th style={{ width: 70 }}>Odds</th>
              <th style={{ width: 70 }}>Stake</th>
              <th style={{ width: 150 }}>Thời gian cược</th>
              <th style={{ width: 140 }}>Ghi chú</th>
            </tr>
          </thead>
          <tbody>
            {timeState.map((item, index) => {
              return (
                <tr key={item.id}>
                  <td>
                    <IconButton color="info" onClick={itemAdd(index + 1, timeState, setTimeState)}>
                      <AddOutlined />
                    </IconButton>
                  </td>
                  <td>
                    <IconButton color="error" onClick={itemDelete(index, timeState, setTimeState)}>
                      <RemoveOutlined />
                    </IconButton>
                  </td>
                  <td>
                    <OutlinedInput defaultValue={item.index} onChange={onTimePropChange("index", item)} />
                  </td>
                  <td>
                    <OutlinedInput defaultValue={item.refNo} onChange={onTimePropChange("refNo", item)} onPaste={onTimePaste(item)} />
                  </td>
                  <td>
                    <OutlinedInput defaultValue={item.company} onChange={onTimePropChange("company", item)} />
                  </td>
                  <td>
                    <OutlinedInput defaultValue={item.ip} onChange={onTimePropChange("ip", item)} />
                  </td>
                  <td>
                    <OutlinedInput defaultValue={item.member} onChange={onTimePropChange("member", item)} />
                  </td>
                  <td>
                    <OutlinedInput defaultValue={item.case} onChange={onTimePropChange("case", item)} />
                  </td>
                  <td>
                    <OutlinedInput defaultValue={item.bet} onChange={onTimePropChange("bet", item)} />
                  </td>
                  <td>
                    <OutlinedInput defaultValue={item.type} onChange={onTimePropChange("type", item)} />
                  </td>
                  <td>
                    <OutlinedInput defaultValue={item.betValue} onChange={onTimePropChange("betValue", item)} />
                  </td>
                  <td>
                    <OutlinedInput defaultValue={item.odds} onChange={onTimePropChange("odds", item)} />
                  </td>
                  <td>
                    <OutlinedInput defaultValue={item.stake} onChange={onTimePropChange("stake", item)} />
                  </td>
                  <td>
                    <OutlinedInput defaultValue={item.time} onChange={onTimePropChange("time", item)} />
                  </td>
                  <td>
                    <OutlinedInput defaultValue={item.message} onChange={onTimePropChange("message", item)} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {reportState.imageData && (
        <div className={classes.reportImage}>
          <Typography variant="h4" align="center">
            Report photo
          </Typography>
          <p>
            <img src={reportState.imageData} style={{ width: "100%" }} />
          </p>
        </div>
      )}
      {isCreateImage && (
        <ToImage report={{ ...reportState }} compareList={[...compareState]} timeList={[...timeState]} onComplete={onToImageComplete} />
      )}
    </DrawerComp>
  );
};

export default ReportPostPage;

const useStyles = makeStyles({
  root: {
    overflow: "auto",
    minHeight: 300,
    fontFamily: "arial",

    "& table": {
      marginBottom: 20,
      width: "100%",
      minWidth: 1300,
      borderCollapse: "collapse",
      border: "1px #66666655 solid",
      borderBottomWidth: 0,
      borderRightWidth: 0,

      "& th, & td": {
        padding: 1,
        whiteSpace: "nowrap",
        verticalAlign: "middle",
        borderBottom: "1px #66666655 solid",
        borderRight: "1px #66666655 solid",
      },
      "& th": {
        backgroundColor: "#88888811",
      },
      "& .MuiOutlinedInput-root": {
        width: "100%",
        height: 28,

        "&:hover": {
          backgroundColor: "#88888822",
        },

        "& .MuiOutlinedInput-input": {
          padding: 0,
          textAlign: "center",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          display: "none",
        },
      },

      "& *": {
        fontSize: 12,
        fontFamily: "arial",
      },
    },

    "& .red": {
      color: "#f00",
    },
    "& .highlight": {
      backgroundColor: "#ff0",
    },
    "& .redlight": {
      backgroundColor: "#f00",
    },
  },
  reportImage: {
    marginTop: 50,
  },
});

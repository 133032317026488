import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import * as Const from "./const";

import * as en from "./language/en";

const resources = {
  en,
  //   en: {
  //     translation: {
  //       common: {
  //         ok: 'OK',
  //         close: 'Close',
  //       },
  //     },
  //   },
};

i18n.use(initReactI18next).init({
  resources,
  lng: Const.language, // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;

// export let domainName = "sp.vscan.co";
export let domainName = "sp.vscans.cc";
export let domainURL = "https://" + domainName;
export let apiURL = "https://" + domainName + "/";

if (process.env.NODE_ENV === "development") {
   //   domainName = "localhost:3000";
   //   domainURL = "http://" + domainName;
   //   apiURL = "http://localhost:5001/";
}

export const loginURL = domainURL + "/auth/login";

export const drawerWidth = 160;

export const language = "en";

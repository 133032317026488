import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  TextField,
  InputAdornment,
  Button,
  Stack,
  Pagination,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
  Dialog,
  Link,
} from "@mui/material";
import { Add, MoreVert, SearchRounded } from "@mui/icons-material";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";

import moment from "moment";

import { onEnterState } from "../../utility/form";
import DrawerComp from "../common/drawerComp";

import { ReportManageService, ReportPostService } from "./vscanService";

const ReportManagePage = () => {
  let navigate = useNavigate();
  const [compState, setCompState] = useState();
  const [filterState, setFilterState] = useState();
  const [listState, setListState] = useState();
  const [itemSelected, setItemSelected] = useState();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const isXS = useMediaQuery((theme) => theme.breakpoints.only("xs"));

  useEffect(() => {
    setFilterState({
      orderBy: "DateCreatedDec",
      pageIndex: 1,
    });

    ReportManageService.init().then((res) => {});
  }, []);

  useEffect(() => {
    if (filterState) getItemList();
  }, [filterState]);

  const getItemList = () => {
    ReportManageService.getList(filterState).then((res) => {
      setListState(res);
    });
  };

  const onPageChange = (event, value) => {
    setFilterState({ ...filterState, pageIndex: value });
  };

  const onItemAdd = () => {
    navigate("/reportPostPage");
  };

  const onItemEdit = (item) => {
    navigate("/reportPostPage/" + item.id);
  };

  const onItemDelete = (item) => {
    setItemSelected(item);
    setIsDeleteModal(true);
  };

  const onItemDeleteSubmit = () => {
    setIsDeleteModal(false);
    ReportPostService.delete(itemSelected.id).then((res) => {
      getItemList();
    });
  };

  return (
    <DrawerComp title="User manage">
      <Container maxWidth="md">
        <Stack>
          <Stack direction="row" alignItems="flex-end" justifyContent="space-between">
            <TextField
              sx={{ maxWidth: 200 }}
              placeholder="Search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchRounded />
                  </InputAdornment>
                ),
              }}
              onKeyUp={onEnterState("keyword", setFilterState)}
            />
            <Button variant="outlined" onClick={onItemAdd} startIcon={<Add />}>
              Add report
            </Button>
          </Stack>
          {listState && (
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography>{listState.totalItems} items</Typography>
              <Pagination
                size={isXS ? "small" : "medium"}
                count={listState?.totalPages}
                page={filterState.pageIndex}
                onChange={onPageChange}
                siblingCount={isXS ? 0 : 1}
              />
            </Stack>
          )}
          {listState && listState.itemList && (
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell padding="none" />
                    <TableCell style={{ paddingLeft: 0, whiteSpace: "nowrap" }}>ID</TableCell>
                    <TableCell>Member</TableCell>
                    <TableCell>ID BCPT</TableCell>
                    <TableCell>Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listState.itemList.map((item) => (
                    <TableRow key={item.id} hover>
                      <TableCell width="1%" padding="none">
                        <PopupState variant="popover" popupId="demo-popup-menu">
                          {(popupState) => (
                            <>
                              <IconButton {...bindTrigger(popupState)}>
                                <MoreVert />
                              </IconButton>
                              <Menu {...bindMenu(popupState)}>
                                <MenuItem
                                  onClick={() => {
                                    popupState.close();
                                    onItemEdit(item);
                                  }}
                                  dense
                                >
                                  Edit
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    popupState.close();
                                    onItemDelete(item);
                                  }}
                                  dense
                                >
                                  Delete
                                </MenuItem>
                              </Menu>
                            </>
                          )}
                        </PopupState>
                      </TableCell>
                      <TableCell style={{ paddingLeft: 0 }}>
                        <b>{item.id}</b>
                      </TableCell>
                      <TableCell>
                        <Link onClick={() => onItemEdit(item)}>{item.member}</Link>
                      </TableCell>
                      <TableCell>{item.idReport}</TableCell>
                      <TableCell>{moment(item.dateCreated).format("DD/MM/YYYY HH:mm")}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Stack>
      </Container>
      <Dialog PaperProps={{ variant: "popup" }} open={isDeleteModal} onClose={() => setIsDeleteModal(false)}>
        {itemSelected && (
          <p>
            Do you want to delete user?
            <br />"{itemSelected.id + " - " + (itemSelected.member ?? "") + " - " + (itemSelected.idReport ?? "")}"
          </p>
        )}
        <div>
          <Button onClick={onItemDeleteSubmit} color="error" variant="outlined">
            OK
          </Button>
          <Button onClick={() => setIsDeleteModal(false)}>Close</Button>
        </div>
      </Dialog>
    </DrawerComp>
  );
};

export default ReportManagePage;

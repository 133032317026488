import { useEffect, useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Stack,
  Paper,
  Dialog,
} from "@mui/material";

import { Msg, onChangeState } from "../../utility/form";

import DrawerComp from "../common/drawerComp";

import { ProfileService } from "./userService";

const ProfilePage = () => {
  const [itemState, setItemState] = useState(null);
  const [isInfoModal, setIsInfoModal] = useState(false);
  const [msgs, setMsgs] = useState([]);

  useEffect(() => {
    setItemState(null);
    setMsgs([]);

    ProfileService.init().then((res) => {
      if (res) {
        setItemState({
          ...res.item,
        });
      }
    });
  }, []);

  const onSubmit = () => {
    ProfileService.submit(itemState).then((res) => {
      setMsgs(res.msgs);
      if (res.success) setIsInfoModal(true);
    });
  };

  if (!itemState) return <></>;

  return (
    <DrawerComp title="Profile">
      <Container maxWidth="sm">
        <Paper variant="form">
          <Stack>
            <Typography variant="h5" align="center">
              Update profile
            </Typography>
            <Stack direction={{ xs: "column", sm: "row" }}>
              <TextField
                name="$$$$asdsadasd"
                label="User name"
                disabled
                defaultValue={itemState.userName}
              />
              <TextField
                label="Full name"
                defaultValue={itemState.fullName}
                onChange={onChangeState("fullName", setItemState)}
                helperText={<Msg target="fullName" msgs={msgs} />}
              />
            </Stack>
            <Stack direction={{ xs: "column", sm: "row" }}>
              <TextField
                autoComplete="off"
                name="$$$qwwewq"
                type="password"
                label="Password"
                onChange={onChangeState("password", setItemState)}
                helperText={<Msg target="password" msgs={msgs} />}
              />
              <TextField
                autoComplete="off"
                name="$$$qwwewq"
                type="password"
                label="Confirm password"
                onChange={onChangeState("confirmPassword", setItemState)}
                helperText={<Msg target="confirmPassword" msgs={msgs} />}
              />
            </Stack>
            <Stack direction={{ xs: "column", sm: "row" }}>
              <TextField
                required
                label="Email"
                defaultValue={itemState.email}
                onChange={onChangeState("email", setItemState)}
                helperText={<Msg target="email" msgs={msgs} />}
              />
              <TextField
                label="Phone number"
                defaultValue={itemState.phoneNumber}
                onChange={onChangeState("phoneNumber", setItemState)}
                helperText={<Msg target="phoneNumber" msgs={msgs} />}
              />
            </Stack>
            <Button variant="contained" onClick={onSubmit}>
              Submit
            </Button>
          </Stack>
        </Paper>
      </Container>
      <Dialog
        PaperProps={{ variant: "popup" }}
        open={isInfoModal}
        onClose={() => setIsInfoModal(false)}
      >
        <p>The task was successful</p>
        <Button
          onClick={() => setIsInfoModal(false)}
          color="success"
          variant="outlined"
        >
          Close
        </Button>
      </Dialog>
    </DrawerComp>
  );
};

export default ProfilePage;

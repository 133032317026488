import { createTheme } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";

const customTheme = ({ palette }) =>
  createTheme({
    palette: { ...palette },
    typography: {
      fontSize: 12,
    },
    components: {
      MuiContainer: {
        defaultProps: {
          disableGutters: true,
        },
      },
      MuiPaper: {
        variants: [
          {
            props: { variant: "form" },
            style: {
              padding: "24px 16px",
              backgroundColor: "#88888811",
            },
          },
          {
            props: { variant: "popup" },
            style: {
              padding: "12px 24px 20px",
            },
          },
        ],
      },
      MuiStack: {
        defaultProps: {
          spacing: 2,
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: 36,
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: (props) => {
            return {
              textTransform: "uppercase",
              borderTop: "1px solid #99999966",
            };
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: "standard",
          fullWidth: true,
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            whiteSpace: "nowrap",
          },
        },
      },
    },
  });

export const DarkThemButton = ({ mode, onClick }) => (
  <IconButton onClick={onClick} sx={{ position: "fixed", right: 16, bottom: 16 }}>
    {mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
  </IconButton>
);

export default customTheme;

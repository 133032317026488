import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Typography,
  TextField,
  Button,
  Stack,
  Box,
  Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { Msg, onChangeState, onEnter } from "../../utility/form";

import { LoginService } from "./userService";
import { CurrentService } from "./currentService";

const LoginPage = () => {
  const [itemState, setItemState] = useState({
    userName: "",
    password: "",
  });
  const [msgs, setMsgs] = useState([]);
  let navigate = useNavigate();

  useEffect(() => {
    const user = CurrentService.getCurrent()?.user;
    if (user) navigate(user.isAdmin ? "/userManage" : "/profile");
  }, []);

  const onSubmit = () => {
    LoginService.submit(itemState).then((res) => {
      setMsgs(res.msgs);
      if (res.success && res.data?.token != null) {
        LoginService.tokenSet(res.data.token);
        CurrentService.check().then((res) => {
          if (res?.user)
            navigate(res.user.isAdmin ? "/userManage" : "/profile");
        });
      }
    });
  };

  return (
    <Box height="100vh" width="100%" display="flex" alignItems="center">
      <Container maxWidth="sm">
        <Paper variant="form">
          <Stack>
            <Typography variant="h5" align="center">
              Login
            </Typography>
            <input
              name="$$username"
              style={{ position: "absolute", top: -9999 }}
            />
            <input
              name="$$password"
              type="password"
              style={{ position: "absolute", top: -9999 }}
            />
            <TextField
              autoComplete="off"
              name="$$$$asdsadasd"
              label="User name"
              onChange={onChangeState("userName", setItemState)}
              onKeyUp={onEnter(onSubmit)}
            />
            <TextField
              autoComplete="off"
              name="$$$qwwewq"
              type="password"
              label="Password"
              onChange={onChangeState("password", setItemState)}
              onKeyUp={onEnter(onSubmit)}
            />
            <Msg target="login" icon msgs={msgs} />
            <Button variant="contained" onClick={onSubmit}>
              Submit
            </Button>
            <Button component={Link} to="/profile">
              Forget password
            </Button>
          </Stack>
        </Paper>
      </Container>
    </Box>
  );
};

export default LoginPage;

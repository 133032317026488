import { useState, useEffect, useMemo } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { Box, CssBaseline } from "@mui/material";

import i18n from "./module/common/i18n"; // required
import customTheme, { DarkThemButton } from "./module/common/customTheme";

import LoginPage from "./module/user/loginPage";
import UserManagePage from "./module/user/userManagePage";
import ProfilePage from "./module/user/profilePage";
import ReportManagePage from "./module/vscan/reportManagePage";
import ReportPostPage from "./module/vscan/reportPostPage";
import ToImage from "./module/vscan/toImage";
import ReportLoginPage from "./module/vscan/reportLoginPage";
import DashboardPage from "./module/vscan/dashboardPage";

import { CurrentService } from "./module/user/currentService";

const App = () => {
  const [current, setCurrent] = useState();
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("darkMode") === "true"
  );
  let navigate = useNavigate();

  const theme = useMemo(
    () =>
      customTheme({
        palette: {
          mode: darkMode ? "dark" : "light",
        },
      }),
    [darkMode]
  );

  const onDarkMode = () => {
    const _darkMode = !darkMode;
    localStorage.setItem("darkMode", _darkMode);
    setDarkMode(_darkMode);
  };

  useEffect(() => {
    CurrentService.check().then((res) => {
      setCurrent(res);

      if (window.location.pathname === "/") navigate("/auth/login");
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {current && (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <Routes>
            <Route path="/" element={<></>} />
            <Route path="login" element={<LoginPage />} />
            <Route path="userManage" element={<UserManagePage />} />
            <Route path="profile" element={<ProfilePage />} />
            <Route path="toImage" element={<ToImage />} />
            <Route path="reportManagePage" element={<ReportManagePage />} />
            <Route path="reportPostPage" element={<ReportPostPage />}>
              <Route path=":id" element={<ReportPostPage />} />
            </Route>
            <Route path="auth/login" element={<ReportLoginPage />} />
            <Route path="dashboard" element={<DashboardPage />} />
          </Routes>
          {current.user && (
            <DarkThemButton mode={theme.palette.mode} onClick={onDarkMode} />
          )}
        </Box>
      )}
    </ThemeProvider>
  );
};

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.querySelector("#root")
);

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Fade from "@mui/material/Fade";

import Viewer from "react-viewer";

import { dashboardStyles, rootStyles } from "./styles";

import { ReportLoginService, RepositoryService } from "./vscanService";
import moment from "moment";

const DashboardPage = () => {
  const rootClass = rootStyles();
  const dashboardClass = dashboardStyles();
  let navigate = useNavigate();
  const [report, reportSet] = useState();
  const [imagePopup, imagePopupSet] = useState(false);
  const [imageViewer, imageViewerSet] = useState(false);

  useEffect(() => {
    const token = ReportLoginService.tokenGet();
    if (token)
      RepositoryService.reportCheck({ token, hasImage: true }).then((res) => {
        if (!res.success) {
          navigate("/auth/login");
          return;
        }
        res.imageName =
          res.member + moment(res.dateCreated).format("-DD-MM") + ".png";
        reportSet(res);
      });
    else navigate("/auth/login");
  }, []);

  const imagePopupToggle = (show) => imagePopupSet(show);

  const imageViewerSetToggle = (show) => {
    if (show) {
      imageViewerSet(true);
      imagePopupToggle(false);
    } else {
      imageViewerSet(false);
      imagePopupToggle(true);
    }
  };

  const onLogout = () => {
    ReportLoginService.logoutSubmit().then((res) => {
      navigate("/auth/login");
    });
  };

  if (!report) return <div className={rootClass.root}></div>;

  return (
    <div className={rootClass.root}>
      <div className={dashboardClass.container}>
        <div className={dashboardClass.header}>
          <div className="left">
            <img className="logo" src="/image/logo-vscan.jpg" />
            <div className="icon btnMenuLeft">
              <i className="fal fa-bars"></i>
            </div>
          </div>
          <div className="right">
            <div className="userBox">
              <div className="ipReport">
                Xin chào: <span>{report.idReport}</span>
              </div>
              <a className="icon btnMenuRight">
                <i className="fal fa-bars"></i>
              </a>
            </div>
            <div className="toolbar">
              <a className="icon">
                <i className="fal fa-globe"></i>
              </a>
              <a className="icon logout" onClick={() => onLogout()}>
                <i className="fal fa-sign-out"></i>
              </a>
            </div>
          </div>
        </div>
        <div className={dashboardClass.body}>
          <div className="left"></div>
          <div className="right">
            <div className="title">Bảng kiểm soát</div>
            <div className="reportBox">
              <div className="title">Hình ảnh</div>
              <button
                onClick={() => imagePopupToggle(true)}
                className="button primary"
              >
                Chi tiết
              </button>
            </div>
          </div>
        </div>
        <div className={dashboardClass.footer}>2020 © Vscan</div>
      </div>
      <Fade in={imagePopup}>
        <div>
          <div className={dashboardClass.imagePopup}>
            <div onClick={() => imagePopupToggle(false)} className="backdrop" />
            <div className="container">
              <div className="title">Báo cáo phân tích</div>
              <div
                className="imageItem"
                onClick={() => imageViewerSetToggle(true)}
              >
                <img src={report.imageData} />
                <button className="button primary">{report.imageName}</button>
              </div>
            </div>
          </div>
        </div>
      </Fade>
      <Viewer
        rotatable={false}
        scalable={false}
        visible={imageViewer}
        onClose={() => imageViewerSetToggle(false)}
        onMaskClick={() => imageViewerSetToggle(false)}
        images={[{ src: report.imageData, alt: report.imageName }]}
      />
    </div>
  );
};

export default DashboardPage;

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Grow from "@mui/material/Grow";

import { onChangeState, onEnter } from "../../utility/form";

import { loginStyles, rootStyles } from "./styles";

import { ReportLoginService, RepositoryService } from "./vscanService";

const ReportLoginPage = () => {
  const rootClass = rootStyles();
  const loginClass = loginStyles();
  const [itemState, itemStateSet] = useState({
    userName: "",
    password: "",
  });
  const [msgs, setMsgs] = useState([]);
  let navigate = useNavigate();

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const userName = query.get("user");
    if (userName) itemStateSet({ ...itemState, userName });
    else {
      const token = ReportLoginService.tokenGet();
      if (token)
        RepositoryService.reportCheck({ token }).then((res) => {
          if (res.success) navigate("/dashboard");
          else ReportLoginService.logoutSubmit();
        });
    }
  }, []);

  const onSubmit = () => {
    ReportLoginService.submit(itemState).then((res) => {
      setMsgs(res.msgs);
      if (res.success && res.data?.token != null) {
        ReportLoginService.tokenSet(res.data.token);
        navigate("/dashboard");
      }
    });
  };

  return (
    <div className={rootClass.root}>
      <div className={loginClass.container}>
        <div className={loginClass.form}>
          <img
            src="/image/logo-vscan.jpg"
            alt="logo win4all"
            style={{ maxWidth: 250 }}
          />
          <h3 className="title">Services</h3>
          {msgs?.length > 0 && (
            <Grow in={msgs?.length > 0}>
              <div className="error">Thông tin không chính xác</div>
            </Grow>
          )}
          <input
            value={itemState.userName}
            onChange={onChangeState("userName", itemStateSet)}
            onKeyUp={onEnter(onSubmit)}
            placeholder="Tên đăng nhập"
            autoComplete="off"
          />
          <input
            type="password"
            onChange={onChangeState("password", itemStateSet)}
            onKeyUp={onEnter(onSubmit)}
            placeholder="Mật khẩu"
            autoComplete="off"
          />
          <div className="footer">
            <button
              className="button"
              onClick={onSubmit}
              disabled={!(itemState.userName && itemState.password)}
            >
              Đăng nhập
            </button>
          </div>
          <a href="https://vscan.cc" target="_blank">
            <i className="fas fa-home" style={{ fontSize: 12 }} />
            <span>Trang Chủ</span>
          </a>
        </div>
        <div className={loginClass.copyright}>2020 © VSCAN Application</div>
      </div>
    </div>
  );
};

export default ReportLoginPage;

import * as Service from "../../utility/serviceHelper";

export const ReportManageService = {
  init: async () => await Service.get("api/report/manage/init"),
  getList: async (param) =>
    await Service.get("api/report/manage/getList", param),
};

export const ReportPostService = {
  init: async (param) => await Service.get("api/report/reportPost/init", param),
  submit: async (param) =>
    await Service.post("api/report/reportPost/submit", param),
  delete: async (param) =>
    await Service.post("api/report/reportPost/delete", param),
};

export const ReportLoginService = {
  tokenGet: () => localStorage.getItem("tokenReport"),
  tokenSet: (token) => localStorage.setItem("tokenReport", token),
  submit: async (param) =>
    await Service.post("api/report/ReportLogin/submit", param),
  logoutSubmit: async () => {
    await Service.get("api/report/ReportLogin/logout", {
      token: localStorage.getItem("tokenReport"),
    });
    localStorage.removeItem("tokenReport");
  },
};

export const RepositoryService = {
  reportCheck: async (param) =>
    await Service.get("api/report/Repository/reportCheck", param),
};

import * as ConstHelper from "./constHelper";
import * as Const from "../module/common/const";

export const tokenSet = (token) => {
  localStorage.setItem("token", token);
};

export const tokenGet = () => {
  return localStorage.getItem("token");
};

export const tokenRemove = () => {
  return localStorage.removeItem("token");
};

export const post = async (endpoint, param) => {
  let token = tokenGet();

  let headers = {
    "Content-Type": "application/json",
  };
  if (token) headers.authorization = "Bearer " + token;

  let response;
  try {
    const res = await fetch(Const.apiURL + endpoint, {
      method: "POST",
      body: JSON.stringify(param),
      crossDomain: true,
      headers: headers,
    });
    response = await responseProcess(res);
  } catch (error) {
    response = { error: true, statusText: error };
    console.log(response);
  }

  return response;
};

export const get = async (endpoint, param) => {
  let token = tokenGet();

  let headers = {};
  if (token) headers.authorization = "Bearer " + token;

  let query = "";
  for (const key in param)
    if (param[key] != null) {
      if (Array.isArray(param[key])) {
        for (var i = 0; i < param[key].length; i++) {
          query += key + "=" + encodeURIComponent(param[key][i]) + "&";
        }
      } else query += key + "=" + encodeURIComponent(param[key]) + "&";
    }
  if (query) endpoint += "?" + query.substring(0, query.length - 1);

  let response;
  try {
    const res = await fetch(Const.apiURL + endpoint, {
      method: "GET",
      crossDomain: true,
      headers: headers,
    });
    response = await responseProcess(res);
  } catch (error) {
    response = { error: true, statusText: error };
    console.log(response);
  }

  return response;
};

const responseProcess = async (response) => {
  switch (response.status) {
    case 200: {
      if (
        response.headers.get("content-type").indexOf("application/json") !== -1
      ) {
        const responseJson = await response.json();
        if (
          !responseJson.code ||
          responseJson.code === ConstHelper.apiCode.success
        )
          responseJson.success = true;
        else responseJson.error = true;

        return responseJson;
      } else return response;
    }
    case 401:
      tokenRemove();
      response.unauthorized = true;
      response.code = "unauthorized";
      response.error = true;
      window.location = Const.loginURL;
      return response;
    default:
      response.code = "other error";
      response.error = true;
      console.log(response);
      return response;
  }
};

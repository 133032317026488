import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  Typography,
  Toolbar,
  ListItemText,
  ListItemIcon,
  ListItem,
  List,
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  ListItemButton,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import AssessmentIcon from "@mui/icons-material/Assessment";
import PeopleIcon from "@mui/icons-material/People";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import LogoutIcon from "@mui/icons-material/Logout";

import * as Const from "./const";

import { CurrentService } from "../user/currentService";
import { LoginService } from "../user/userService";

const ResponsiveDrawer = ({ _window, isDrawerToggle }) => {
  const [current, setCurrent] = useState(CurrentService.getCurrent());
  const [mobileOpen, setMobileOpen] = useState(false);
  const drawerWidth = Const.drawerWidth;

  useEffect(() => {
    if (isDrawerToggle === undefined) return;
    handleDrawerToggle();
  }, [isDrawerToggle]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const onLogout = () => {
    LoginService.logoutSubmit();
    window.location = "/";
  };

  const drawer = (
    <div>
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          VScan
        </Typography>
      </Toolbar>
      {current?.user?.isAdmin && (
        <>
          <Divider />
          <List>
            <ListItemButton component={Link} to="/userManage" key="userManage">
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="User manage" />
            </ListItemButton>
            <ListItemButton component={Link} to="/reportManagePage" key="reportManagePage">
              <ListItemIcon>
                <AssessmentIcon />
              </ListItemIcon>
              <ListItemText primary="Report" />
            </ListItemButton>
          </List>
        </>
      )}
      <Divider />
      <List>
        <ListItemButton component={Link} to="/profile" key="profile">
          <ListItemIcon>
            <AssignmentIndIcon />
          </ListItemIcon>
          <ListItemText primary="Profile" />
        </ListItemButton>
        <ListItemButton onClick={onLogout} key="Logout">
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
      </List>
    </div>
  );

  const container = _window !== undefined ? () => _window().document.body : undefined;

  return (
    <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

const DrawerComp = ({ title, button, children }) => {
  const [isDrawerToggle, setIsDrawerToggle] = useState();
  const drawerWidth = Const.drawerWidth;

  const handleDrawerToggle = () => {
    setIsDrawerToggle(!isDrawerToggle);
  };

  return (
    <>
      <ResponsiveDrawer isDrawerToggle={isDrawerToggle} />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            {title}
          </Typography>
          {button}
        </Toolbar>
      </AppBar>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { xs: "100%", sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </>
  );
};

export default DrawerComp;

import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Typography } from "@mui/material";
export const Msg = ({ target, msgs, text, icon }) => {
  const { t } = useTranslation();
  const [label, setLabel] = useState("");

  useEffect(() => {
    let textTrans = "";
    if (msgs && msgs.length > 0) {
      const msg = msgs.find((item) => item.target === target);
      if (msg) {
        textTrans = t(msg.code);
        if (textTrans === null) textTrans = target;
      }
    }
    setLabel(textTrans);
  }, [msgs]);
  return label ? (
    text ? (
      label
    ) : icon ? (
      <Alert component="span" severity="error">
        {label}
      </Alert>
    ) : (
      <Typography component="span" sx={{ color: "error.main" }}>
        {label}
      </Typography>
    )
  ) : null;
};

export const onChangeState = (prop, setProp) => (event) => {
  setProp((state) => ({ ...state, [prop]: event.target.value }));
};

export const onEnterState = (prop, setProp) => (event) => {
  event.key === "Enter" &&
    setProp((state) => ({ ...state, [prop]: event.target.value }));
};

export const onEnter = (callback) => (event) => {
  event.key === "Enter" && callback();
};

import * as Service from "../../utility/serviceHelper";

export const LoginService = {
  tokenGet: async () => Service.tokenGet(),
  tokenSet: async (token) => Service.tokenSet(token),
  submit: async (param) => await Service.post("api/user/login/submit", param),
  logoutSubmit: async () => {
    await Service.get("api/user/login/logout");
    Service.tokenRemove();
  },
};

export const ProfileService = {
  init: async () => await Service.get("api/user/profile/init"),
  submit: async (param) => await Service.post("api/user/profile/submit", param),
};

export const UserManageService = {
  init: async () => await Service.get("api/user/manage/init"),
  getList: async (param) => await Service.get("api/user/manage/getList", param),
  ban: async (param) => await Service.post("api/user/manage/ban", param),
};

export const UserPostService = {
  init: async (param) => await Service.get("api/user/userPost/init", param),
  submit: async (param) =>
    await Service.post("api/user/userPost/submit", param),
  delete: async (param) =>
    await Service.post("api/user/userPost/delete", param),
};

import { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Stack,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";

import { Msg, onChangeState } from "../../utility/form";

import { UserPostService } from "./userService";

const UserPostModal = ({ isOpen, onOK, onClose, item }) => {
  const [compState, setCompState] = useState({});
  const [itemState, setItemState] = useState(null);
  const [msgs, setMsgs] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {}, []);

  useEffect(() => {
    if (isOpen) onModalOpen();
    else onModalClose();
  }, [isOpen]);

  const onModalOpen = () => {
    setIsModalOpen(true);
    onInit();
  };

  const onModalClose = () => {
    setIsModalOpen(false);
    onClose();
  };

  const handleChange = (prop) => (event) => {
    setItemState({ ...itemState, [prop]: event.target.value });
  };

  const onInit = () => {
    setItemState(null);
    setMsgs([]);
    UserPostService.init({ id: item?.id }).then((res) => {
      if (!res.success) return;

      setCompState({
        title: item ? "Edit user" : "Add user",
      });
      setItemState({
        ...res.item,
      });
    });
  };

  const onSubmit = () => {
    UserPostService.submit(itemState).then((res) => {
      setMsgs(res.msgs);
      if (res.success) {
        onModalClose();
        if (onOK) onOK();
      }
    });
  };

  return (
    <Dialog open={isModalOpen} onClose={onModalClose} fullWidth={true}>
      <DialogTitle>{compState.title}</DialogTitle>
      <DialogContent>
        {itemState && (
          <Stack>
            <Stack direction={{ xs: "column", sm: "row" }}>
              <TextField
                required
                autoComplete="off"
                name="$$$Nsdsadasd"
                label="User name"
                defaultValue={itemState.userName}
                onChange={onChangeState("userName", setItemState)}
                helperText={<Msg target="userName" msgs={msgs} />}
              />
              <TextField
                label="Full name"
                defaultValue={itemState.fullName}
                onChange={onChangeState("fullName", setItemState)}
                helperText={<Msg target="fullName" msgs={msgs} />}
              />
            </Stack>
            <Stack direction={{ xs: "column", sm: "row" }}>
              <TextField
                required
                autoComplete="off"
                name="$$$Pwwewq"
                type="password"
                label="Password"
                onChange={onChangeState("password", setItemState)}
                helperText={<Msg target="password" msgs={msgs} />}
              />
              <TextField
                required
                autoComplete="off"
                name="$$$qwwewq"
                type="password"
                label="Confirm password"
                onChange={onChangeState("confirmPassword", setItemState)}
                helperText={<Msg target="confirmPassword" msgs={msgs} />}
              />
            </Stack>
            <Stack direction={{ xs: "column", sm: "row" }}>
              <TextField
                required
                label="Email"
                defaultValue={itemState.email}
                onChange={onChangeState("email", setItemState)}
                helperText={<Msg target="email" msgs={msgs} />}
              />
              <TextField
                label="Phone number"
                defaultValue={itemState.phoneNumber}
                onChange={onChangeState("phoneNumber", setItemState)}
                helperText={<Msg target="phoneNumber" msgs={msgs} />}
              />
            </Stack>
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onModalClose}>Close</Button>
        <Button onClick={onSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserPostModal;
